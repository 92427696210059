import React from "react";
import { AnimatePresence, MotionConfig } from "framer-motion";
export const wrapPageElement = ({ element }) => (
  <MotionConfig reducedMotion="reduced">
    <AnimatePresence initial={false} mode="wait">
      {element}
    </AnimatePresence>
  </MotionConfig>
);

// must be on Gatsby v2.28 or later -- previous versions had a bug with getSavedScrollPosition that was resolved with v2.28
// wait until page exit animation has completed before updating scroll position
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // transition hossza a layoutban *1000 a ms miatt
  const TRANSITION_DELAY = process.env.GATSBY_TRANSITION_ANIMATION_LENGTH; //0.60 * 1000

  // if it's a "normal" route
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY);
  }

  // if we used the browser's forwards or back button
  else {
    // breaking change in shouldUpdateScroll browser API hook:
    // https://github.com/gatsbyjs/gatsby/issues/23842
    // looks like they fixed it in Gatsby v. 2.28.1
    // https://github.com/gatsbyjs/gatsby/pull/27384

    const savedPosition = getSavedScrollPosition(location) || [0, 0];
    const scrollPage = (x, y) => {
      document.querySelector("html").style.scrollBehavior = "auto";
      setTimeout(() => {
        window.scrollTo(x, y);
      }, 50);
      setTimeout(() => {
        document.querySelector("html").style.scrollBehavior = "smooth";
      }, 600);
    };
    window.setTimeout(() => scrollPage(...savedPosition), TRANSITION_DELAY);
    //window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }

  return false;
};
