exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-templates-stock-indexes-general-index-page-jsx": () => import("./../../../src/templates/stock/indexes/generalIndexPage.jsx" /* webpackChunkName: "component---src-templates-stock-indexes-general-index-page-jsx" */),
  "component---src-templates-stock-indexes-guide-index-page-jsx": () => import("./../../../src/templates/stock/indexes/guideIndexPage.jsx" /* webpackChunkName: "component---src-templates-stock-indexes-guide-index-page-jsx" */),
  "component---src-templates-stock-indexes-instructors-index-page-jsx": () => import("./../../../src/templates/stock/indexes/instructorsIndexPage.jsx" /* webpackChunkName: "component---src-templates-stock-indexes-instructors-index-page-jsx" */),
  "component---src-templates-stock-indexes-services-index-page-jsx": () => import("./../../../src/templates/stock/indexes/servicesIndexPage.jsx" /* webpackChunkName: "component---src-templates-stock-indexes-services-index-page-jsx" */),
  "component---src-templates-stock-subpages-contacts-page-jsx": () => import("./../../../src/templates/stock/subpages/contactsPage.jsx" /* webpackChunkName: "component---src-templates-stock-subpages-contacts-page-jsx" */),
  "component---src-templates-stock-subpages-general-page-jsx": () => import("./../../../src/templates/stock/subpages/generalPage.jsx" /* webpackChunkName: "component---src-templates-stock-subpages-general-page-jsx" */),
  "component---src-templates-stock-subpages-guide-page-jsx": () => import("./../../../src/templates/stock/subpages/guidePage.jsx" /* webpackChunkName: "component---src-templates-stock-subpages-guide-page-jsx" */),
  "component---src-templates-stock-subpages-instructor-page-jsx": () => import("./../../../src/templates/stock/subpages/InstructorPage.jsx" /* webpackChunkName: "component---src-templates-stock-subpages-instructor-page-jsx" */),
  "component---src-templates-stock-subpages-pricing-page-jsx": () => import("./../../../src/templates/stock/subpages/pricingPage.jsx" /* webpackChunkName: "component---src-templates-stock-subpages-pricing-page-jsx" */),
  "component---src-templates-stock-subpages-services-page-jsx": () => import("./../../../src/templates/stock/subpages/servicesPage.jsx" /* webpackChunkName: "component---src-templates-stock-subpages-services-page-jsx" */),
  "component---src-templates-stock-subpages-study-material-page-jsx": () => import("./../../../src/templates/stock/subpages/studyMaterialPage.jsx" /* webpackChunkName: "component---src-templates-stock-subpages-study-material-page-jsx" */)
}

